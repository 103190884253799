import '../scss/styles.scss';
import '../css/style.css';

import * as bootstrap from 'bootstrap';
import './vendor/navik.menu.js';
import 'lity/dist/lity.js';

if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR');
	});
}

const announcementElement = document.querySelector('#news-announcement-brow');
const closeButton = document.querySelector('.close-button');

const announcementClosed = document.cookie
	.split('; ')
	.find((row) => row.startsWith('AnnouncementClosed='))
	?.split('=')[1];

var now = new Date();
var time = now.getTime();
time += 3600 * 1000;
now.setTime(time);

if (!announcementClosed) {
	document.cookie = 'AnnouncementClosed=false; max-age=3600;';
}

if (announcementClosed == 'true') {
	announcementElement.classList.add('!tw-hidden');
}

closeButton.addEventListener('click', function () {
	announcementElement.classList.add('!tw-hidden');

	if (announcementClosed == 'false') {
		document.cookie = 'AnnouncementClosed=true;';
	}
});
